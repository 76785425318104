nav {
  background-color: rgb(19, 18, 26);
  text-align: center;
  font-weight: bold;
  width: 100%
}

nav a {
  padding: 0.5em 0;
  width: 12em;
  display: inline-block;
  color: white;
  text-decoration: none;
}

nav a.active {
  color: rgb(116, 141, 255);
  text-decoration: none;
}

nav a:hover {
  color: rgb(69, 142, 214);
  text-decoration: none;
}

.Navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Navigation-pages {
  align-items: end;
}