.CompanyDetail{
  height: 100vh;
}

.CompanyDetail-image {
  width: 50px;
}

.CompanyDetail-name {
  text-align: left;
  font-weight: 600;
  font-size: x-large;
  color: rgb(232, 230, 227);
  text-shadow: rgb(37, 40, 42) 2px 2px 5px;
}

.CompanyDetail-description {
  font-weight: 300;
  font-size: x-large;
  text-align: left;
  padding: 10px;
  color: rgb(232, 230, 227);
  text-shadow: rgb(37, 40, 42) 2px 2px 5px;
}

.CompanyDetail-header {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}