.Homepage {
  color: rgb(232, 230, 227);
  text-shadow: rgb(37, 40, 42) 2px 2px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
  height: 75vh;
}

.Homepage-buttons {
  display: flex;
  flex-direction: row;
}

.btn {
  margin: 10px
}