.JobCard {
  display: flex;
  flex-direction: column;
  margin: 10px;
  background-color: azure;
  padding: 10px;
}

.JobCard-title {
  text-align: left;
  font-weight: 600;
  font-size: small;
}

.JobCard-text {
  font-weight: 300;
  font-size: small;
  text-align: left;
}

.JobCard-companyName {
  text-align: left;
  font-size: small;
}
